import request from '@/utils/request'
export function exchangelist(params) {
  return request({
    url: '/gift/gift/exchangeLog',
    method: 'get',
    params: params
  })
}
// 记录对应的收货地址
export function getaddress(params) {
  return request({
    url: '/gift/gift/getAddress',
    method: 'get',
    params: params
  })
}
// 取消兑换
export function cancel(params) {
  return request({
    url: '/gift/gift/cancel',
    method: 'get',
    params: params
  })
}
