<template>
  <div class="exchange_box">
    <p class="myclass-title">
      <span class="title-text">兑换记录</span>
      <span class="title-border"></span>
    </p>
    <div class="search">
      <el-input
        v-model="name"
        placeholder="搜索礼品记录"
        style="width:650px;"
      ></el-input>
      <el-button type="primary" @click="searchM">搜索</el-button>
    </div>
    <!-- 兑换记录 -->
    <ul class="record_box">
      <li v-for="(item, index) in list" :key="index">
        <div class="imgbox">
          <img :src="item.img" alt="">
        </div>
        <div class="record_desc">
          <div>{{ item.gift_name }}</div>
          <p>{{ item.create_time }}</p>
        </div>
        <div class="record_right">
          <div class="zlb">{{ item.spent_zlb }}{{ coin_name }}*{{ item.num }}</div>
          <div>
            <!-- <el-button
              type="text"
              @click="editAdress(item)"
            >修改地址</el-button> -->
            <el-button type="text" @click="wlinfo(item.id)">查看物流</el-button>
            <!-- <el-button v-show="item.print_status==0&&item.gift_source==1" type="text" @click="cancel(item.id)">取消兑换</el-button> -->
          </div>
        </div>
      </li>
    </ul>

    <!-- 无数据显示 -->
    <div v-if="count===0" class="noDataShow">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有兑换记录~</p>
    </div>
    <div v-else class="feny">
      <el-pagination
        v-show="count > 0"
        class="page"
        background
        layout="prev, pager, next"
        :page-size="limit"
        :total="count"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 新增收货地址 -->
    <div v-show="showModel" class="more_messbox">
      <div class="more-center">
        <img src="@/assets/image/white_close.png" alt="" @click="closeBtn">
        <p>{{ addressTitle }}</p>
        <el-form
          ref="ruleForm"
          :model="addressForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="收货人" prop="name">
            <el-input v-model="addressForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="addressForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="所在区域" prop="addresstring">
            <el-input
              v-model="addressForm.addresstring"
              @focus="getCity()"
            ></el-input>
            <!-- <input
              v-model="addressForm.address"
              readonly="readonly "
              type="text"
              @focus="getProvinceM()"
            /> -->
          </el-form-item>
          <el-form-item label="详细地址" prop="addressInfo">
            <el-input v-model="addressForm.addressInfo"></el-input>
          </el-form-item>
          <el-form-item style="text-align:left;">
            <input
              id="checkbox1"
              type="checkbox"
              :checked="moradd"
              value="设为默认地址"
              style="margin-right:3px;vertical-align:middle;"
            >
            <span>设为默认地址</span>
          </el-form-item>
        </el-form>
        <div class="save_address">
          <el-button
            type="primary"
            @click="saveAddress('ruleForm')"
          >保存</el-button>
        </div>
      </div>
      <!-- 城市表单 -->
      <div v-show="showCity" class="city">
        <p class="title">所在地区<span @click="backUp()">[返回上一级]</span></p>
        <img
          src="https://newoss.zhulong.com/cdn/bbs/img/indeximg/close.gif"
          alt=""
          @click="closeFun()"
        >
        <div>
          <span
            v-for="(val, key) in district"
            v-show="!childrenShow"
            :key="val"
            @click="writeCity(district[key], key)"
          >{{ key }}</span>
          <span
            v-for="(val, key) in children"
            v-show="childrenShow && !childrenShow2"
            :key="val"
            @click="writeCity2(children[key], key)"
          >{{ key }}</span>
          <span
            v-for="(val, key) in children2"
            v-show="childrenShow2"
            :key="val"
            @click="writeCity3(children2[key], key)"
          >{{ key }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { exchangelist, cancel } from '@/api/ExchangeRecord'
import { getRow } from '@/api/mine'
import {
  getProvince,
  getCity,
  getCounty,
  addAddress
  // addressList,
} from '@/api/giftexchange'
export default {
  data() {
    return {
      limit: 20,
      page: 1,
      total: 0,
      list: [],
      name: '',
      count: 0,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        showCity: false
      },
      mraddress: false,
      showModel: false,
      moradd: false,
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            type: 'number',
            message: '电话号必须为为数字',
            trigger: 'change'
          },
          {
            validator: function(rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) === false) {
                callback(new Error('手机号格式错误'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        addresstring: [
          { required: true, message: '请填写地址', trigger: 'blur' }
        ],
        addressInfo: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ]
      },
      // 城市列表
      showCity: false,
      district: [],
      children: [],
      children2: [],
      childrenShow: false,
      childrenShow2: false,
      addressForm: {
        name: '',
        phone: '',
        address: [],
        addressId: [],
        addressInfo: '',
        addresstring: ''
      },
      address: '',
      addresslist: [],
      itemAddressid: '',
      showopen: false,
      addressTitle: '编辑地址',
      coin_name: ''
    }
  },
  mounted() {
    this.getConfig()
    this.getlist()
    this.getProvinceM()
  },
  methods: {
    getConfig() {
      getRow({ type: 1 }).then(res => {
        if (res.errNo === 0) {
          const origin = window.location.origin
          // if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
          //   this.coin_name = '注考币'
          // } else {
            this.coin_name = res.result.name
          // }
        }
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getlist()
    },
    getlist() {
      const post = {
        page: this.page,
        limit: this.limit
      }
      exchangelist(post)
        .then((res) => {
          const errNo = res.errNo
          if (errNo === 0) {
            const result = res.result
            const count = result.count
            const list = result.list
            this.count = count
            this.list = [...this.list, ...list]
            if (this.list.length < this.count) {
              this.page++
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    wlinfo(val) {
      // tracking   ///   type : 1  我的快递详情   2  兑换记录的详情
      this.$router.push({
        path: 'tracking',
        query: { id: val, type: 2 }
      })
    },
    searchM(evt) {
      exchangelist({ keyword: this.name })
        .then((res) => {
          this.list = res.result.list
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 取消兑换
    cancel(id) {
      const info = {
        id: id
      }
      cancel(info).then(res => {
        if (res.errNo === 0) {
          this.$message.success(res.result)
          this.page = 1
          this.limit = 20
          this.list = []
          this.getlist()
        }
      })
    },
    // 编辑收货地址
    editAdress(row) {
      this.$alert('地址信息不能修改', '提示', {})

      // getaddress({ address_id: row.address_id }).then((res) => {
      //   if (res.result.is_default === 1) {
      //     this.moradd = true;
      //   } else {
      //     this.moradd = false;
      //   }
      //   var row = res.result;
      //   this.showModel = true;
      //   this.addressForm.addressId[0] = row.province_id;
      //   this.addressForm.addressId[1] = row.city_id;
      //   this.addressForm.addressId[2] = row.county_id;
      //   this.addressForm.address[0] = row.address_json.province;
      //   this.addressForm.address[1] = row.address_json.city;
      //   this.addressForm.address[2] = row.address_json.county;
      //   this.addressForm.addressInfo = row.detail_address;
      //   this.addressForm.phone = Number(row.mobile);
      //   this.addressForm.name = row.receiver;
      //   this.addressForm.addresstring =
      //     this.addressForm.address[0].toString() +
      //     this.addressForm.address[1].toString() +
      //     this.addressForm.address[2].toString();
      //   console.log(this.addressForm);
      // });
    },
    // 获取省列表
    getProvinceM() {
      getProvince()
        .then((res) => {
          this.district = res.result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击省获取市列表
    writeCity(val, key) {
      // 省的id
      this.addressForm.address[0] = key
      this.addressForm.addressId[0] = val
      this.addressForm.addresstring = this.addressForm.address[0].toString()
      this.childrenShow = true
      // 获取市列表
      getCity({ id: val })
        .then((res) => {
          this.children = res.result
        })
        .catch((err) => {
          console.log(err)
        })
      return
    },
    // 点击市，获取区县信息列表
    writeCity2(val, key) {
      this.childrenShow2 = true
      // 市的id
      this.addressForm.address[1] = key
      this.addressForm.addresstring =
                this.addressForm.address[0].toString() +
                this.addressForm.address[1].toString()
      this.addressForm.addressId[1] = val
      getCounty({ id: this.addressForm.addressId[1] })
        .then((res) => {
          this.children2 = res.result
          this.addressForm.address[2] = key
          this.addressForm.addressId[2] = val
        })
        .catch((err) => {
          console.log(err)
        })
        // this.getCityFun();
    },
    writeCity3(val, key) {
      this.addressForm.address[2] = key
      this.addressForm.addresstring =
                this.addressForm.address[0].toString() +
                this.addressForm.address[1].toString() +
                this.addressForm.address[2].toString()
      this.addressForm.addressId[2] = val
      console.log(this.addressForm.addressId[2])
      // this.getCityFun();
    },
    getCity() {
      this.showCity = true
    },
    closeFun() {
      this.showCity = false
    },
    // 返回上一级
    backUp() {
      // this.getCityFun();
      if (this.childrenShow2 === true) {
        this.childrenShow2 = false
      } else {
        this.childrenShow = false
      }
    },
    cancelCity() {
      // this.getCityFun();
      this.showCity = false
      this.childrenShow = false
      this.childrenShow2 = false
    },
    closeBtn() {
      this.showModel = false
    },
    // 保存新地址
    saveAddress(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          const info = {
            province_id: this.addressForm.addressId[0],
            city_id: this.addressForm.addressId[1],
            county_id: this.addressForm.addressId[2],
            town_id: 0,
            province: this.addressForm.address[0],
            city: this.addressForm.address[1],
            county: this.addressForm.address[2],
            town: '',
            mobile: this.addressForm.phone,
            detail_address: this.addressForm.addressInfo,
            id: this.itemAddressid,
            receiver: this.addressForm.name
          }
          addAddress(info)
            .then((res) => {
              console.log(res)
              this.showModel = false
              this.getlist()
            })
            .then((err) => {
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    // 设置默认地址
    mraddressM(val) {
      mraddress({ id: val })
        .then((res) => {
          console.log(res)
          this.getaddress()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .exchange_box {
    padding: 0 20px;
  }
  .search {
    margin-bottom: 30px;
    padding-left: 120px;
    text-align: left;
    /deep/.el-input__inner {
      border: 2px solid #f15555;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    /deep/.el-button {
      padding: 12px 30px;
      border-radius: 0px;
    }
  }
  .record_box {
    margin-bottom: 20px;
    li {
      border-bottom: 1px solid #ddd;
      padding: 35px 70px 35px 120px;
      overflow: hidden;
      .imgbox {
        float: left;
        width: 97px;
        height: 85px;
        margin-right: 30px;
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .record_desc {
        width: 400px;
        text-align: left;
        font-style: 16px;
        float: left;
        > div {
          padding: 18px 0px;
        }
      }
    }
  }
  .record_right {
    text-align: right;
    float: right;
  }
  .zlb {
    margin-bottom: 20px;
    line-height: 25px;
  }
  // 新增收货地址
  .more_messbox {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0px;
    .more-center {
      width: 500px;
      background: white;
      position: absolute;
      left: 50%;
      top: 20%;
      margin-left: -250px;
      padding: 0 35px;
      img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        line-height: 70px;
        height: 70px;
        // font-weight: bold;
        color: #333;
      }
    }
  }
  .save_address {
    padding: 10px 0px 40px 0px;
  }
  .city {
    width: 420px;
    min-height: 320px;
    background: #fff;
    z-index: 102;
    box-shadow: 0px 0px 8px #999;
    position: absolute;
    top: 65%;
    left: 70%;
    transform: translate(-50%, -50%);
    img {
      position: absolute;
      right: 0;
      top: 0;
    }
    .title {
      text-align: center;
      margin-top: 15px;
      color: #000;
      span {
        color: #ee2e2e;
        cursor: pointer;
      }
    }
    div {
      width: 380px;
      height: auto;
      margin: 20px 0 10px 35px;
      display: flex;
      flex-wrap: wrap;
      span {
        display: block;
        margin: 10px 1px;
        min-width: 60px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;
    font-size: 14px;
  }
  .noDataShow {
    position: absolute;
    width: 180px;
    height: 180px;
    top: 50%;
    left: 50%;
    margin-top: -90px;
    // margin-left: -180px;
    img {
      display: block;
      margin: auto;
    }
    p {
      text-align: center;
      font-size: 14px;
      color: #999999;
      margin-top: 30px;
    }
  }

  input[type="checkbox"]::after {
    position: absolute !important;
    top: 0 !important;
    color: #000 !important;
    width: 15px !important;
    height: 15px !important;
    display: inline-block !important;
    visibility: visible !important;
    padding-left: 0px !important;
    text-align: center !important;
    content: " " !important;
    border-radius: 3px !important;
  }

  input[type="checkbox"]:checked::after {
    content: "✓" !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: #ee2e2e !important;
  }
  /deep/.el-message-box__message p {
    text-align: center;
  }
  .feny {
    padding-bottom: 20px;
  }
</style>
<style>
  .ant-message{
    z-index: 9999 !important;
  }
</style>
